export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const algoliaConfig = {
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  deudoresIndex: process.env.REACT_APP_ALGOLIA_DEUDORES_INDEX,
  clientsIndex: process.env.REACT_APP_ALGOLIA_CLIENTS_INDEX,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};
