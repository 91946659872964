import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to your preferred error tracking service
    console.error('ErrorBoundary caught an error:', error, errorInfo);
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh'
          }}
        >
          <h2>¡Algo salió mal!</h2>
          <pre style={{ color: 'red', maxWidth: '800px', overflow: 'auto' }}>
            {error?.toString()}
          </pre>
          {errorInfo && (
            <pre style={{ fontSize: '12px', maxWidth: '800px', overflow: 'auto' }}>
              {errorInfo.componentStack}
            </pre>
          )}
          <button
            type="button"
            onClick={() => {
              // Only clear session storage
              sessionStorage.clear();
              // Reload current page instead of redirecting to root
              window.location.reload();
            }}
            style={{
              padding: '10px 20px',
              marginTop: '20px',
              cursor: 'pointer',
              backgroundColor: '#1976d2',
              color: 'white',
              border: 'none',
              borderRadius: '4px'
            }}
          >
            Recargar página
          </button>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
